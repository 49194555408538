h1 {
    color: rebeccapurple
}

.timer {
    margin-left: 50%;
    
}    


.MuiPaper-root{
    max-width: 25%;
    height: 25%


}
