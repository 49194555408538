.page {
  width: 100%;
  height: 100%;
  position: abolute;
}

.top-tags {
  bottom: auto;
  top: 35px;
}

.tags {
  color: #ffd700;
  opacity: 0;
  position: absolute;
  bottom: 0;
  left: 120px;
  font-size: 18px;
  font-family: 'La Belle Aurore';
}

.bottom-tag-html {
  margin-left: -20px;
  
}

.container {
  width: 100%;
  will-change: contents;
  height: 90%;
  min-height: 566px;
  position: absolute;
  opacity: 0;
  top: 5%;
  margin: 0 auto;
  z-index: 1;
  transform-style: preserve-3d;
  animation: fadeIn 1s forwards;
  animation-delay: 1s;
}

.about-page,
.contact-page,
.projects-page {
  .text-zone {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 35%;
    vertical-align: middle;
    display: table-cell;
    max-height: 90%;

    h1 {
      font-size: 53px;
      font-family: 'Coolvetica';
      color: #8d99ae;
      font-weight: 400;
      margin-top: 0;
      position: relative;
      margin-bottom: 40px;
      left: 10px;

      &::before {
        
        font-family: 'La Belle Aurore';
        font-size: 18px;
        position: absolute;
        margin-top: -10px;
        left: -10px;
        opacity: 0.6;
        line-height: 18px;
      }

      &::after {
        font-family: 'La Belle Aurore';
        font-size: 18px;
        line-height: 18px;
        position: absolute;
        left: -30px;
        bottom: -20px;
        margin-left: 20px;
        opacity: 0.6;
      }
    }


    h2{
      color: #6096ba;
    }
    p {
      font-size: 13px;
      color: white;
      font-weight: 300;
      min-width: fit-content;
      animation: pulse 1s;

      &:nth-of-type(1) {
        animation-delay: 1.1s;
      }
      &:nth-of-type(2) {
        animation-delay: 1.2s;
      }
      &:nth-of-type(3) {
        animation-delay: 1.3s;
      }
      
    }
  }
  .text-animate-hover {
    &:hover {
      color: white;
    }
  }
  .flat-button {
    color: #ffd700;
    font-size: 13;
    font-weight: normal;
    letter-spacing: 4px;
    font-family: sans-serif;
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid #ffd700;
    margin-top: 25px;
    float: left;
    animation: fadeIn 0.9s .9s backwards;
    white-space: nowrap;

    &:hover {
      background: #ffd700;
      color: #333;
    }
  }
}
